import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useKnowledgebase() {
    const toast = useToast()
    const refBlogListTable = ref(null)
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'image',label: 'Image (900x540)', sortable: false },
        { key: 'title',label:'Title', sortable: true },
        { key: 'category_name',label:'Category', sortable: false },
        { key: 'is_active',label:'Active', sortable: false },
    
        { key: 'actions' },
        
    ]
    const perPage = ref(10)
    const totalBlogs = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
   
    const dataMeta = computed(() => {
    const localItemsCount = refBlogListTable.value ? refBlogListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBlogs.value,
        }
    })
    const refetchData = () => {
    
        refBlogListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
        console.log('change');
        refetchData()
    })
    const fetchBlogs = (ctx, callback)=>{
      const offset = perPage.value*(currentPage.value-1);
        store
        .dispatch('app-knowledge/fetchData', {
          limit:perPage.value,
          offset:offset,
          keywords:searchQuery.value
        })
        .then(response => {
          
         
          const blogs = response.data.data;
          console.log('blogs',response);
          totalBlogs.value = response.data.total
        //  const { invoices, total } = response.data
         callback(blogs)
         // callback(invoices)
          //totalInvoices.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching invoices' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        
      }
      const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-knowledge/fetchDelete',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Blog",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }

    return {
      fetchBlogs,
      
      tableColumns,
      perPage,
      currentPage,
      totalBlogs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBlogListTable,
      deleteData,
      refetchData,
    }
}