import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx, queryParams){

            var data = JSON.stringify(queryParams);
      
            return new Promise((resolve, reject) => {
                
                axios
                .post('knowledge/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        
        },
        fetchDelete(ctx,id){
        return new Promise((resolve, reject) => {
            axios
            .delete('/knowledge/v1/delete/'+id)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
        }
        
    }
}